import React from "react"

const BlogArticle = ({ postsToRender }) => { 
    return (
    	<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
    		{postsToRender.map((node, index) => (
    			<div key={node.id} className="background-image-holder">
                    <a className="block" href={'/'+node.url+'/'}>
                    	<div className="block__image" css={{ backgroundImage: `url(${node.image.file.url})`}}></div>
                        <div className="block__title">
                            <span>{node.createdAt}</span>
                            <h5>{node.titlePage}</h5>
                        </div>
                    </a>
                </div>
    		))}
    	</div>
    );
};

export default BlogArticle;
import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Footer from "../components/footer.js"
import Posts from "../components/blog-article";

const postsPerPage = 12;
let arrayForHoldingPosts = [];

const BlogArticles = styled.div`
    .background-image-holder {
        .block {
            &__image {
                border-radius: 6px 6px 0 0;
                height: 230px;
                width: 100%;
                background-position: center !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
            &__title {
                border-radius: 0 0 6px 6px;
                border: 1px solid #ececec;
                padding: 1.85714286em;

                span {
                    font-size: 1em;
                    line-height: 1.85714286em;
                    color: #666;
                }

                h5 {
                    font-size: 1.2em;
                    line-height: 1.85714286em;
                    font-weight: 600;
                    color: #252525;
                }
            }
        }
    }
`

export default function Blog({data}) {

	const posts = data.allContentfulBlog.edges.map(({ node }) => ( node))

  	const [postsToShow, setPostsToShow] = useState(posts.slice(0, postsPerPage));
  	const [next, setNext] = useState(postsPerPage);

  	const loopWithSlice = (start, end) => {
    	const slicedPosts = posts.slice(start, end);
    	arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    	setPostsToShow(arrayForHoldingPosts);
  	};

  
  	useEffect(() => {
    	loopWithSlice(0, postsPerPage);
  	}, []);

  	const handleShowMorePosts = () => {
    	loopWithSlice(next, next + postsPerPage);
    	setNext(next + postsPerPage);
  	};

	return (
		<>	
			<Seo title="Блог о матрасах и товаров для сна | SleepAdvisor" description="Интересные статьи про матрасы и другие товары для комфортного сна. Информационные обзоры, рейтинги моделей и брендов, полезные советы." />
			<Helmet>
				<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "Блог"}]}`}</script>
			</Helmet>
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item active" aria-current="page">Блог</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>Блог</h1>
				</div>
			</div>
			<BlogArticles className="py-16 max-w-[540px] md:max-w-[720px] lg:max-w-7xl mx-auto px-4 sm:px-6">
				<Posts postsToRender={postsToShow} />
				
	            	{postsToShow.length !== posts.length ? (<button className="button button_primary button_s mt-10 mx-auto" onClick={handleShowMorePosts}>Показать еще</button>) : (``)}
	        	
	        </BlogArticles>
            <Footer />
	    </>
    )
}

export const query = graphql`
  	query Blog {
    	allContentfulBlog(sort: { fields: [createdAt], order: DESC }) {
      		edges {
	        	node {
	          		id
	          		url
	          		titlePage
	          		updatedAt
	          		createdAt(formatString: "DD MMMM YYYY", locale: "ru")
	          		image {
			          	file {
			            	url
			            	details {
			              		image {
				                	height
				                	width
			              		}
			            	}
			          	}
			        }
	        	}
	      	}
    	}
  	}
`